import { fetchConfig } from '@/lib/fetchConfig';
import { useQuery } from '@tanstack/react-query';

export const queryExpertiseArticles = `query($category: String! ) {
  articleCollection(limit: 100, where: {category: $category}){
    items{
      ...on Article{
        slug
        subCategory
        seo {
          title
          description
          canonicalUrl
          nextPage
        }
      }
    }
  }
}
`;

export const queryExpertiseArticle = `
  query($locale: String!, $category: String!, $slug: String!, $subCategory: String!) {
    articleCollection(
      limit: 1,
      locale: $locale,
      where: { category: $category, slug: $slug, subCategory: $subCategory }
    ) {
      items {
        ... on Article {
          slug
          category
          subCategory
          landingPage
          featuredTitle
          featuredDescription {
            json
          }
          published
          writtenBy
          accordion {
            json
            links {
              assets {
                hyperlink {
                  url
                }
                block {
                  sys {
                    id
                  }
                  url
                }
              }
              entries {
                block {
                  sys {
                    id
                  }
                  __typename
                  ... on CardSection {
                    __typename
                    internalTitle
                    title
                    layout
                    sectionContentCollection(limit: 10) {
                      items {
                        ... on Card {
                          __typename
                          onlyImagevideo
                          cardTemplate
                          linkUrl
                          linkIconTitle
                          title
                          videoUrl
                          shortDescription {
                            json
                          }
                          cardImage {
                            width
                            height
                            url
                            title
                          }
                        }
                        ... on Accordion {
                          __typename
                          title
                          description {
                            json
                          }
                        }
                      }
                    }
                  }
                  ... on ContentFlag {
                    __typename
                    internalTitle
                  }
                }
              }
            }
          }
          virtualShowRoom {
            ... on Card {
              __typename
              cardTemplate
              cardImage {
                title
                width
                height
                url
              }
              shortDescription {
                json
              }
              linkUrl
              title
              internalTitle
              linkIconTitle
              linkMailto
              icon {
                url
                title
                width
                height
              }
            }
          }
          featuredProjectsCollection {
            items {
              __typename
              ... on ArticleSection {
                layout
                title
                sectionContentCollection {
                  items {
                    ... on Article {
                      slug
                      subCategory
                      category
                      featuredTitle
                      featuredDescription {
                        json
                      }
                      featuredImage {
                        title
                        url
                        width
                        height
                      }
                    }
                  }
                }
              }
            }
          }
          nextPageNavigation {
            internalTitle
            buttonText
            buttonUrl
          }
          sidebarNextPageNavigationCollection {
            items {
              internalTitle
              buttonText
              buttonUrl
            }
          }
          personBanner {
            ... on PersonBanner {
              internalTitle
              title
              quote
              personName
              personPosition
              picturePosition
              personMail
              pictureOfPerson {
                title
                description
                width
                height
                url
              }
              linkedIn {
                title
                urlPath
                document {
                  title
                  description
                  url
                  width
                  height
                }
              }
            }
          }
          seo {
            title
            description
            canonicalUrl
            nextPage
            noindex
            nofollow
            image {
              url
              width
              height
              title
              description
            }
            ldType
            ldAuthor
            ldAuthorUrl
            ldPublished
            ldUpdated
          }
        }
      }
    }
  }
`;

export const queryExpertiseContentArticle = `
  query($locale: String!, $category: String!, $slug: String!, $subCategory: String!) {
    articleCollection(
      limit: 1,
      locale: $locale,
      where: { category: $category, slug: $slug, subCategory: $subCategory }
    ) {
      items {
        ... on Article {
          content {
            json
            links {
              assets {
                hyperlink {
                  url
                }
                block {
                  sys {
                    id
                  }
                  url
                }
              }
              entries {
                block {
                  sys {
                    id
                  }
                  __typename
                  ... on PicturesInRow {
                    title
                    imagesCollection {
                      items {
                        url
                      }
                    }
                  }
                  ... on YouTubeVideo {
                    videoUrl
                  }
                  ... on AwardsAndCertificates {
                    __typename
                    title
                    imagesCollection {
                      items {
                        title
                        description
                        url
                        size
                      }
                    }
                  }
                  ... on ArticleSection {
                    sectionContentCollection(limit: 1) {
                      items {
                        title
                        featuredTitle
                        featuredDescription {
                          json
                        }
                        slug
                        category
                        featuredImage {
                          width
                          height
                          url
                          title
                        }
                      }
                    }
                    __typename
                    title
                    layout
                    numberOfLoadedContent
                    sys {
                      id
                    }
                  }
                  ... on PictureAndTextInRow {
                    titleRow
                    description {
                      json
                    }
                    picturePosition
                    picture {
                      title
                      url
                      width
                      height
                    }
                  }
                  ... on CardSection {
                    __typename
                    internalTitle
                    title
                    layout
                    sectionContentCollection(limit: 10) {
                      items {
                        ... on Card {
                          __typename
                          onlyImagevideo
                          cardTemplate
                          linkUrl
                          linkIconTitle
                          title
                          videoUrl
                          shortDescription {
                            json
                          }
                          cardImage {
                            width
                            height
                            url
                            title
                          }
                        }
                        ... on Accordion {
                          __typename
                          title
                          description {
                            json
                          }
                        }
                      }
                    }
                  }
                  ... on Quote {
                    quoteText
                  }
                  ... on Accordion {
                    __typename
                    title
                    description {
                      json
                    }
                  }
                  ... on NavigationButton {
                    __typename
                    showTitle
                    internalTitle
                    buttonText
                    buttonUrl
                    document {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const fetchExpertiseContentArticle = async (
  locale: string,
  category: string,
  subCategory: string,
  slug: string
) => {
  const variables = { locale, category, subCategory, slug };
  const res = await fetch(fetchConfig.endpoint as string, {
    method: 'POST',
    ...fetchConfig.params,
    body: JSON.stringify({ query: queryExpertiseContentArticle, variables })
  });
  const json = await res.json();

  if (json.errors) {
    const { message } = json.errors[0];

    throw new Error(message);
  }

  return json.data;
};

const fetchExpertiseArticle = async (
  locale: string,
  category: string,
  subCategory: string,
  slug: string
) => {
  const variables = { locale, category, subCategory, slug };
  const res = await fetch(fetchConfig.endpoint as string, {
    method: 'POST',
    ...fetchConfig.params,
    body: JSON.stringify({ query: queryExpertiseArticle, variables })
  });
  const json = await res.json();

  if (json.errors) {
    const { message } = json.errors[0];

    throw new Error(message);
  }

  return json.data;
};

const useExpertiseArticle = (
  locale: any,
  category: string,
  subCategory: any,
  slug: any
) => {
  return useQuery({
    queryKey: [slug, locale],
    refetchOnWindowFocus: false,
    queryFn: () => fetchExpertiseArticle(locale, category, subCategory, slug)
  });
};

const useExpertiseContentArticle = (
  locale: any,
  category: string,
  subCategory: any,
  slug: any
) => {
  return useQuery({
    queryKey: ['content', locale],
    refetchOnWindowFocus: false,
    queryFn: () =>
      fetchExpertiseContentArticle(locale, category, subCategory, slug)
  });
};

export {
  useExpertiseArticle,
  fetchExpertiseArticle,
  fetchExpertiseContentArticle,
  useExpertiseContentArticle
};
